// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
.left {
  .drh-left-menu {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;

    .link,
    .link:hover {
      color: #222;
      text-decoration: none;
    }
  }
  .is-close {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    .menu-button {
      display: inline-block;
      margin: 10px 0 0 7px;
      cursor: pointer;
    }
  }

  .is-open {
    .title {
      font-size: 16px;
      font-weight: bold;
      padding: 15px 0 15px 30px;
      border-bottom: 1px solid #d5dbdb;
    }
    .icon {
      width: 40px;
      height: 40px;
      position: absolute;
      text-align: center;
      right: 8px;
      top: 14px;
      cursor: pointer;
    }
    .list-item {
      padding: 15px 20px;
      .item {
        padding: 4px 10px;
        a,
        a:link {
          display: block;
          color: #ec7211;
          font-weight: bold;
          text-decoration: none;
        }
      }
    }
  }
}
