// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
.card {
  .title {
    font-weight: bold;
    font-size: 18px;
    padding: 15px 10px;
    background-color: #fafafa;
    border-bottom: 1px solid #eaeded;
  }
  .item-list {
    padding: 10px 0;
    ul,
    li {
      font-size: 14px;
      margin: 0 0;
      padding: 0 0;
      list-style: none;
    }
    ul {
      padding: 0 20px;
    }
    li {
      padding: 10px 0px;
      border-bottom: 1px solid #eaeded;
      a,
      a:link {
        text-decoration: none;
        color: #0073bb;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
