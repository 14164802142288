// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
.credential {
  .top-tips {
    padding: 10px 0 15px;
    line-height: 1.6em;
    color: #444;
    i {
      color: #222;
    }
    code {
      border: 1px solid #ccc;
      color: #444;
      font-size: 12px;
      background-color: #eee;
      padding: 2px 5px 2px 5px;
      border-radius: 6px;
      margin: 0 2px;
    }
  }
  .tips-title {
    font-size: 16px;
    font-weight: bold;
  }
  .code-info {
    background-color: #f2f3f3;
    padding: 1px 10px;
    font-size: 12px;
  }
  .copy {
    font-size: 12px;
    margin-top: 5px;
    .copy-tips {
      color: green;
      margin: 5px 0 0 5px;
    }
  }
}

.more {
  margin-top: 30px;
  border-top: 1px solid #ddd;
  padding: 20px 0 0 0;
  &-title {
    font-size: 16px;
    color: #222;
    padding-bottom: 10px;
  }
  .open-icon {
    position: absolute;
    margin: -1px 0 0 3px;
  }
}
