// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
.task-status {
  padding-left: 0px;
  .content {
    font-size: 14px;
    padding: 12px 20px 12px 65px;
    background-color: green;
    color: #ffffff;
    .icon {
      position: absolute;
      margin: -3px 0 0 -26px;
    }
    img {
      position: absolute;
      margin: 1px 0 0 -20px;
    }
  }
}

.table-data {
  padding: 5px 40px 120px;
  .box-shadow {
    background-color: #ffffff;
    box-shadow: 0px 2px 2px #ccc;
  }
  .title {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    background-color: #fafafa;
    border-bottom: 1px solid #eaeded;
    padding: 15px 0 15px 0px;
    span.info {
      color: #444;
      font-weight: normal;
    }
    .options {
      display: flex;
      padding: 10px 20px;
      .task-count {
        flex: 1;
      }
      .buttons {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
      }
    }
    .search {
      display: flex;
      padding: 10px 0 0 0;
      .search-input {
        // width: 700px;
        flex: 1;
        padding: 0 0 0 20px;
        input {
          width: 100%;
          max-width: 565px;
          border: 1px solid #aab7b8;
          background-color: #fff;
          height: 32px;
          line-height: 32px;
          padding: 0 5px;
        }
      }
      .pagination {
        width: 300px;
        // text-align: right;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 0px 20px 0 0;
        .cur-page {
          font-size: 16px;
          display: inline-block;
          // height: 24px;
          width: 50px;
          text-align: center;
          line-height: 14px;
          padding: 15px 15px 0;
          vertical-align: top;
        }
        .item {
          display: inline-block;
          width: 28px;
          height: 24px;
          padding: 10px 0 0 0;
          text-align: center;
          line-height-step: 18px;
          cursor: pointer;
          // background: #ccc;
          &.disabled {
            cursor: default;
          }
        }
      }
      .setting-icon {
        // width: 60px;
        text-align: center;
        padding: 5px 10px 0 0;
        // border-left: 1px solid #eaeded;
        cursor: pointer;
      }
    }
  }
  .data-list {
    padding-bottom: 10px;
    .table-wrap {
      width: 100%;
      .table-header {
        width: 100%;
        display: flex;
        border-bottom: 1px solid #eaeded;
      }
      .table-row {
        width: 100%;
        display: flex;
        border: 1px solid transparent;
        border-bottom: 1px solid #eaeded;
        &:hover {
          background-color: #fafafa;
        }
        &.active {
          border: 1px solid #0073bb;
          background-color: #f1faff;
        }
      }
      .table-item {
        padding: 12px 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .icon {
          position: absolute;
          margin: -1px 0 0 -22px;
        }
        // flex: 1;
        &.check-item {
          width: 50px;
          text-align: center;
        }
        &.item-id {
          width: 180px;
        }
        &.create-time {
          width: 200px;
        }
        &.header-item {
          text-align: left;
          flex: 1;
        }
        &.body-item {
          text-align: left;
          flex: 1;
        }
        a,
        a:link {
          text-decoration: none;
          color: #0073bb;
        }
      }
    }
  }
}
