// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.oidc-login {
  min-height: 40vh; // height of the browser viewport
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  .title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    color: #666;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.drh-cron {
  // justify-content: center;
  align-items: center;
}

.fix-rate {
  align-items: center;
}

.info-table {
  background-color: #ccc;
  width: 100%;
  th,
  td {
    text-align: left;
    background-color: #fff;
    padding: 2px 5px;
  }
  th {
    font-size: 12px;
  }
  .object-number {
    width: 80px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
