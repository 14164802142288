// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
.info-bar {
  position: fixed;
  z-index: 9;
  width: 40px;
  top: 40px;
  bottom: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px #ccc;
  &.is-opened {
    width: 280px;
  }
  .is-close {
    .menu-button {
      display: inline-block;
      margin: 10px 0 0 7px;
      cursor: pointer;
    }
  }
  .is-open {
    .title {
      font-size: 16px;
      font-weight: bold;
      padding: 15px 0 15px 30px;
      border-bottom: 1px solid #d5dbdb;
    }
    .icon {
      width: 40px;
      height: 40px;
      position: absolute;
      text-align: center;
      right: 8px;
      top: 14px;
      cursor: pointer;
    }
    .info-content {
      font-size: 14px;
      padding: 15px 20px;
      overflow-y: auto;
      height: calc(100vh - 120px);
      p {
        line-height: 1.8em;
      }
    }
  }
}
